import { render, staticRenderFns } from "./DiamondsInTheRivieraMayaCollection.vue?vue&type=template&id=61fd7c33&scoped=true"
import script from "./DiamondsInTheRivieraMayaCollection.vue?vue&type=script&lang=js"
export * from "./DiamondsInTheRivieraMayaCollection.vue?vue&type=script&lang=js"
import style0 from "./DiamondsInTheRivieraMayaCollection.vue?vue&type=style&index=0&id=61fd7c33&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61fd7c33",
  null
  
)

export default component.exports
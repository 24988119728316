import { render, staticRenderFns } from "./PoloxJazz.vue?vue&type=template&id=68f2dcce&scoped=true"
import script from "./PoloxJazz.vue?vue&type=script&lang=js"
export * from "./PoloxJazz.vue?vue&type=script&lang=js"
import style0 from "./PoloxJazz.vue?vue&type=style&index=0&id=68f2dcce&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68f2dcce",
  null
  
)

export default component.exports